<template>
    <v-layout>
        <!-- o recibo está sendo gerado no SSR -->
    </v-layout>
</template>

<script type="plain/text">
import DocService from "@/scripts/services/doc.service";
    export default {
        components: {
            DocService
        },
        name: "ReceiptList",
        data() {
            return {
                docService: new DocService()
            }
        },
        created(){
            debugger
            let chave = this.$route.query.chave;
            this.docService.getReceipt(chave, this.callbackReceipt);
        },
        methods: {
            callbackReceipt(response){
                 let blob = new Blob([response.data],{ type:'application/pdf'});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "ReciboDoacao.pdf";
                link.click();
            }
        }
    }
</script>

<style scoped>

</style>